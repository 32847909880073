<template>

  <section class="register">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light p-5">
                <div class="brand-logo text-center">
                  <img src="../../../assets/images/logo-color.png">
                </div>
                <h4 class="text-center">¿Eres un usuario nuevo?</h4>
                <h6 class="font-weight-light text-center">Registrarse es fácil. Solo necesitas unos cuantos pasos</h6>

                <form class="pt-3" @submit.prevent="register">
                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg" v-model="company" id="Company" required placeholder="Empresa">
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg" v-model="name" id="" placeholder="Tu nombre" required>
                  </div>
                  <div class="form-group">
                    <input type="email" class="form-control form-control-lg" id="" v-model="email" placeholder="Correo electrónico" required>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control form-control-lg" v-model="username" id="" placeholder="Crea un nombre de usuario" required>
                  </div>
                  <div class="form-group">
                    <input type="password" @keyup="checkmatch" class="form-control form-control-lg" v-model="password" id="" placeholder="Crea una contraseña" required>
                  </div>
                  
                  <div class="form-group">
                    
                    <input type="password" @keyup="checkmatch" class="form-control form-control-lg" v-model="repeat_password" id="" placeholder="Repite la contraseña" required>
                    <b-alert variant="warning" v-if="unmatch" show>El password no coincide</b-alert>
                    
                  </div>
                  <div class="mb-4">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" required class="form-check-input">
                        Acepto los términos y condiciones
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                  <div class="mt-3">
                    <b-alert variant="danger" v-if="errors" show v-text="error_summary"></b-alert>

                    <submitbutton :label="'Registrarme'" :label_loading="'Registrando...'" :loading_flag="loading" :wide="true"/>
                  </div>
                  <div class="text-center mt-4 font-weight-light">
                    ¿Ya tienes una cuenta? <router-link to="/user/login" class="text-primary">Accede</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>

import api from '@/util/api.js';
import submitbutton from '@/components/ui-feature/submit-button';

export default {
  name: 'register',

  components:{

    submitbutton
  },
  data(){

    return{
    company:'',
    name:'',
    username:'',
    email:'',
    password:'',
    repeat_password:'',
    loading:false,
    unmatch:false,
    error_summary:'',
    errors:false,
    }

  },
  methods:{


      checkmatch(){
        if(this.password==this.repeat_password)
          this.unmatch=false;
      },  
      register(){

        this.errors=false;
        this.error_summary='';

        if(this.password==this.repeat_password){


          this.loading=true;

          api.post('/auth/register',{
            
            Company:{name:this.company},
            User:{name:this.name,username:this.username,email:this.email,password:this.password}
            
            
          }).then(response=>{

            const token = response.data.token;
        
            localStorage.setItem('access_token', token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('user_name', response.data.name);
            localStorage.setItem('user_profile', response.data.profile);

            this.$router.push('/');

          }).catch(error=>{


              const errors=error.response.data.errors;
              let str='';
              for(var i in errors){
                  str+=errors[i][0];
              }

              this.error_summary=str;
              this.errors=true;

            console.log(error.response.data);

          }).finally(()=>{


            this.loading=false;
            


          });
        }else{
          this.unmatch=true;
        }

      },

  }
}
</script>